<template>
  <div class="page">
    <Navbar title="云课堂" :callback="back" type="SMP" />
    <div class="top">
      <div class="name">人力资源管理师精讲课程</div>
      <div class="player">
        <vue3VideoPlay
          v-bind="options"
          @loadstart="loadstart"
          controlslist="nodownload"
        />
      </div>
      <van-notice-bar left-icon="volume-o" :text="current" />
    </div>
    <div class="course">
      <div class="title"><van-icon name="notes-o" /> 课程目录</div>
      <van-row
        class="item"
        v-for="video in videoList"
        :key="video.videoCode"
        @click="changeVideo(video)"
      >
        <van-col span="14" class="name text-short"
          ><van-icon name="play-circle-o" /> {{ video.name }}</van-col
        >
        <van-col span="4" class="duration">{{ video.duration }}分钟</van-col>
        <van-col span="6" class="type"
          ><van-tag
            mark
            :color="COLOR_M"
            size="mini"
            v-if="video.type === 'FRE'"
            >免费</van-tag
          ><van-tag
            mark
            :color="COLOR_S1"
            size="mini"
            v-if="video.type === 'PAY'"
            >付费</van-tag
          ></van-col
        >
      </van-row>
    </div>
    <Share ref="share" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import 'vue3-video-play/dist/style.css'
import vue3videoPlay from 'vue3-video-play'
import { Tag, Icon, NoticeBar } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
export default {
  components: {
    Navbar: Navbar,
    Loading: Loading,
    Share: Share,
    [vue3videoPlay.name]: vue3videoPlay,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [NoticeBar.name]: NoticeBar
  },
  data () {
    return {
      shareShow: false,
      loadingShow: false,
      loginState: 'N',
      courseCode: '',
      videoList: [],
      current: '请选择播放课程视频',
      options: {
        width: '100%', // 播放器高度
        height: '100%', // 播放器高度
        color: '#409eff', // 主题色
        muted: false, // 静音
        webFullScreen: false,
        speedRate: ['0.75', '1.0', '1.25', '1.5', '2.0'], // 播放倍速
        autoPlay: true, // 自动播放
        loop: false, // 循环播放
        mirror: false, // 镜像画面
        ligthOff: false, // 关灯模式
        volume: 0.3, // 默认音量大小
        control: true, // 是否显示控制器
        title: '', // 视频名称
        src: '', // 视频源
        type: 'video/mp4' // 视频类型
      }
    }
  },
  mounted () {
    var query = this.$route.query
    this.courseCode = query.courseCode
    this.retrieveVideoList()
  },
  methods: {
    init () {
    },
    async retrieveVideoList () {
      var pd = { courseCode: this.courseCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/train/course/retrieveCourseVideoList', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.videoList = res.data
      }
    },
    changeVideo (video) {
      this.current = '当前播放：' + video.name
      this.options.src = video.videoPath
    },
    loadstart (ev) {
      console.log(ev)
    },
    onCanplay (ev) {
      console.log(ev, '可以播放')
    },
    changeCourse (ref) {
      // var obj = this.$refs.second
      console.log(ref)
    },
    initShare () {
      var title = this.task.promoTitle
      var desc = this.task.promoDesc
      var link = 'https://moc.utopanet.com/train/detail?taskCode=' + this.taskCode
      if (this.role === 'SEL') {
        link = link + '&refereeType=SEL'
      }
      if (this.role === 'CLT') {
        link = link + '&refereeType=CLT'
      }
      var logo = 'https://moc.utopanet.com/static/logo.png'
      this.$refs.share.initParam(title, desc, link, logo)
    },
    validateToken () {
      var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
      if (token === null) {
        this.$router.push({ path: '/mde/bind' })
        return false
      } else {
        return true
      }
    },
    retHome () {
      this.$router.push('/mde/main/home/train')
    },
    shareImage () {
      this.$router.push({ path: '/mde/train/shareImage', query: { taskCode: this.taskCode } })
    },
    back () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  background-color: #f5f5f5;
}
.top {
  background-color: #fff;
  padding: 5px 0px;
  margin-top: 5px;
  .player {
    width: 100%;
    height: 200px;
  }
  .name {
    height: 25px;
    line-height: 25px;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    padding-left: 15px;
  }
}
.course {
  background-color: #fff;
  .title {
    font-size: 15px;
    font-weight: 600;
    padding-left: 10px;
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #eee;
  }
  .item {
    border-bottom: 1px dashed #eee;
    .name {
      text-align: left;
      padding-left: 10px;
      font-size: 13px;
      height: 35px;
      line-height: 35px;
    }
    .duration {
      font-size: 13px;
      height: 35px;
      line-height: 35px;
    }
    .type {
      height: 35px;
      font-size: 13px;
      line-height: 35px;
    }
  }
}
.shareImg {
  width: 90%;
}
</style>
